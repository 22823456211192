import React from "react";

import './Footer.css';

const Footer = () => {
    return (
        <footer className="text-light text-center py-3 fixed-bottom">
            <p className="h6 mb-0">myexpenses 2024 &reg;</p>
        </footer>
    );
};

export default Footer;



